import React, { useEffect, useState } from 'react';
import { Modal, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { radarGetStart } from 'store/ducks/radar/radarGet/actions';
import { radarEmailsColumns } from '../../utils/radarEmailsColumns';
import { StButton, StHeader, StHeaderTitle, StTable } from './styled';
import {
  radarDetailsFiltersZeroLength,
  updateRadarDetailsFilters,
} from 'store/ducks/filters/radar/details/actions';
import { onColumnSort } from 'helpers/sorter';
import { generateFilterDataStructure } from 'helpers/tableService';
import { customResetUpdateFilteredColumnsStart } from 'store/ducks/customUpdateFilteredColumns/actions';

const RadarDetailModal = ({
  radarId,
  closeRadarModal,
  isRadarModalVisible,
}) => {
  const dispatch = useDispatch();
  const { Text } = Typography;
  const [sortFields, setSortFields] = useState({});
  const [localFilters, setLocalFilters] = useState([]);
  const [showTable, setShowTable] = useState(true);
  const [pageCurrent, setPageCurrent] = useState(1);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [pageTotal, setPageTotal] = useState(100);
  const showTotalPage = (total, range) =>
    `Total ${range[0]}-${range[1]} de ${total} itens`;

  const getRadarLoading = useSelector((state) => state.radarGet?.loading);
  const radarEmailsArray = useSelector((state) => state.radarGet?.data);
  const listRadarDetailsMeta = useSelector((state) => state.radarGet?.meta);
  const filtersData = useSelector((state) => state.radarDetailsFilters.filters);
  const toParamsFilters = useSelector(
    (state) => state.radarDetailsFilters.toParamsFilters
  );
  const isDisableClearButton = toParamsFilters?.length === 0;

  const onTablePaginationOrFiltersOrSorterChange = (
    pagination,
    tableFilters,
    sorter
  ) => {
    const filterDataToDispatch = generateFilterDataStructure(tableFilters);

    onColumnSort(sorter, setSortFields);
    setLocalFilters(tableFilters);
    dispatch(updateRadarDetailsFilters(filterDataToDispatch));
    setPerPage(pagination.pageSize);
    setPageTotal(listRadarDetailsMeta?.num_results);
    setPageCurrent(pagination.current);
    setPage(pagination.current);
  };

  useEffect(() => {
    dispatch(
      radarGetStart({ radarId, page, perPage, sortFields, toParamsFilters })
    );
    // eslint-disable-next-line
  }, [page, perPage, sortFields, toParamsFilters]);

  useEffect(() => {
    setPageTotal(listRadarDetailsMeta?.num_results);
  }, [listRadarDetailsMeta]);

  const resetFiltersStates = () => {
    dispatch(radarDetailsFiltersZeroLength());
    dispatch(updateRadarDetailsFilters([]));
    dispatch(customResetUpdateFilteredColumnsStart());
    setSortFields({});
  };

  const handleResetFilters = () => {
    setShowTable(false);
    resetFiltersStates();
  };

  const onCloseRadarModal = () => {
    handleResetFilters();
    closeRadarModal();
  };

  useEffect(() => {
    setShowTable(true);
  }, [showTable]);

  return (
    <Modal
      open={isRadarModalVisible}
      footer={false}
      onCancel={onCloseRadarModal}
      onOk={onCloseRadarModal}
      width="135rem"
      centered
    >
      <StHeader>
        <StHeaderTitle>
          <Text style={{ fontWeight: 500, fontSize: '18px' }}>
            Lista de envio
          </Text>
          <Text>ID Radar: {radarId}</Text>
        </StHeaderTitle>
        <StButton onClick={handleResetFilters} disabled={isDisableClearButton}>
          Limpar filtros
        </StButton>
      </StHeader>
      {showTable && (
        <StTable
          columns={radarEmailsColumns({
            radarId,
            filtersData,
            localFilters,
            toParamsFilters,
            sortFields,
            pageCurrent,
            perPage,
          })}
          dataSource={radarEmailsArray}
          showSorterTooltip={false}
          loading={getRadarLoading}
          onChange={onTablePaginationOrFiltersOrSorterChange}
          pagination={{
            current: page,
            pageSize: perPage,
            total: pageTotal,
            showTotal: showTotalPage,
            showSizeChanger: true,
          }}
          scroll={{ y: window.innerHeight - 350 }}
        />
      )}
    </Modal>
  );
};

export default RadarDetailModal;
