import plans from 'constants/plans';
import {
  eighteenMonthsAgoFromCurrentDay,
  ninetyDaysAgoFromCurrentDay,
} from './dates';
import dayjs from 'dayjs';

export const disableLastMonth = (current, startDate, endDate) => {
  const dateRangeAfterStartDate =
    startDate && current.diff(startDate, 'days') >= 31;
  const dateRangeBeforeEndDate =
    (endDate && endDate.diff(current, 'days') >= 31) ||
    (startDate && startDate.diff(current, 'days') > 0);
  const blockThePast = eighteenMonthsAgoFromCurrentDay >= current;
  const blockTheFuture = current > dayjs().endOf('day');

  return (
    !!dateRangeAfterStartDate ||
    !!dateRangeBeforeEndDate ||
    !!blockThePast ||
    !!blockTheFuture
  );
};

export const disabledDateByPlan = ({ current, planId, ...dates }) => {
  const [startDate, endDate] = Object.values(dates);

  const dateRangeAfterStartDate =
    startDate && current.diff(startDate, 'days') >= 31;

  const dateRangeBeforeEndDate =
    (endDate && endDate.diff(current, 'days') >= 31) ||
    (startDate && startDate.diff(current, 'days') > 0);

  const blockThePast =
    (planId === plans.FREE
      ? ninetyDaysAgoFromCurrentDay
      : eighteenMonthsAgoFromCurrentDay) >= current;

  const blockTheFuture = current > dayjs().endOf('day');

  return (
    !!dateRangeAfterStartDate ||
    !!dateRangeBeforeEndDate ||
    !!blockThePast ||
    !!blockTheFuture
  );
};
