import { call, put } from 'redux-saga/effects';
import errorHandler from 'helpers/errorHandler';
import { alterProfileScopeFailure, alterProfileScopeSuccess } from './actions';
import updateUserScopeProfile from '../../../services/alterScopeProfile';

export function* alterProfileScopeSaga(actions) {
  const { params } = actions.payload;
  try {
    const dataFromAPI = yield call(updateUserScopeProfile, params);
    yield put(alterProfileScopeSuccess(dataFromAPI));
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      yield put(
        alterProfileScopeFailure(
          err.response.data.error.message,
          err.response.data.error
        )
      );
      errorHandler(err?.response?.data?.error?.message, err);
    }
  }
}
