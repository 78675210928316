import { call, put } from 'redux-saga/effects';

import errorHandler from 'helpers/errorHandler';

import {
  createBranchSuccess,
  createBranchError,
} from 'store/ducks/createBranch/actions';

import createBranch from 'services/branchManagement/createBranch';
import { notification } from 'antd';

export function* createBranchSaga(action) {
  try {
    const { payload } = action;
    const apiData = yield call(createBranch, payload);
    yield put(createBranchSuccess(apiData));
    notification.success({
      message: 'Empresa criada com sucesso!',
    });
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      yield put(
        createBranchError(
          err.response.data.error.message,
          err.response.data.error
        )
      );
      errorHandler(err?.response?.data?.error?.message, err);
    } else {
      yield put(createBranchError('Ocorreu um erro:', err));
      errorHandler('Ocorreu um erro:', err);
    }
  }
}
