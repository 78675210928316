/* eslint-disable */
import { lambdaApi } from '../api';

const transformer = (apiResponse) => {
  return {
    data: apiResponse, // confirmar
  };
};

// Create notification
export const createNotification = async ({
  message,
  table,
  fileType,
  scopeId,
  profileId,
  startDate,
  endDate,
  q,
  sortFields,
  clientName,
  searchOption,
  searchText,
  filterType,
  filter,
  acquirerId,
}) => {
  const config = {
    message,
    table,
    file_type: fileType,
    scope_id: scopeId,
    profile_id: profileId,
    start_date: startDate,
    end_date: endDate,
    q,
    sort_fields: sortFields,
    client_name: clientName,
    field: searchOption,
    value: searchText,
    filter_type: filterType,
    filter,
    acquirer_id: acquirerId,
  };
  const response = await lambdaApi().post('/export/create/post', config);
  return transformer(response.data);
};
