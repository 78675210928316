import { call, put } from 'redux-saga/effects';
import { getPermissions } from 'services/auth';
import errorHandler from 'helpers/errorHandler';
import { permissionsSuccess, permissionsFailure } from './actions';

export function* permissionsSaga() {
  try {
    const permissionsData = yield call(getPermissions);
    yield put(permissionsSuccess(permissionsData));
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      yield put(
        permissionsFailure(err.response.data.error, err.response.data.error)
      );
      errorHandler(err?.response?.data?.error?.message, err);
    } else if (err?.response?.data?.error) {
      yield put(
        permissionsFailure(err.response.data.error, err.response.data.error)
      );
      errorHandler('Ocorreu um erro:', err);
    }
  }
}
