import React from 'react';
import PropType from 'prop-types';
import DrawerItem from '../DrawerItem/DrawerItem';
import { Grid } from 'antd';
import { useDispatch } from 'react-redux';
import { isConciler } from 'helpers/authorizationService';
import { updateBriefingStepStart } from 'store/ducks/updateBriefingStep/actions';
import { useNavigate } from 'react-router-dom';
import { usePlansFeaturesPermissions } from 'utils/hooks/usePlansFeaturesPermissions';
import { usePermissions } from 'utils/hooks/usePermissions';
import { dataTestIdHandler } from 'helpers/dataTestIdHandler';
import {
  StDoubleLeftOutlined,
  StFormOutlined,
  StLeftMenuDrawer,
  StLeftMenu,
  StNotificationOutlined,
  StPercentageOutlined,
  StToolOutlined,
  StUploadOutlined,
  leftDrawerBodyStyle,
  StSpin,
  StHomeOutlined,
} from '../../styled';

const LeftDrawerOptions = ({
  collapsed,
  onColappsed,
  userFirstName,
  clientEmail,
  store,
  logoLink,
  closeMenus,
  ...others
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { useBreakpoint } = Grid;
  const breakpoint = useBreakpoint();
  const { loading: permissionsLoading } = usePermissions();
  const { isFeatureAvailable } = usePlansFeaturesPermissions();
  const dataTestPrefix = 'left-drawer-options';

  const onClickDash = () => {
    navigate('/dashboard');
    onColappsed();
  };
  const onClickFees = () => {
    navigate('/taxas');
    onColappsed();
  };
  const onClickImportacao = () => {
    navigate('/importacao-arquivos');
    onColappsed();
  };
  const onClickRegistroImplantacao = () => {
    dispatch(updateBriefingStepStart(0));

    navigate('/registro-de-implantacao');
    onColappsed();
  };
  const onClickManutencao = () => {
    navigate('/manutencao-cnpj');
    onColappsed();
  };
  const onClickOfertas = () => {
    navigate('/banking');
    onColappsed();
  };
  const onClickPlans = () => {
    navigate('/planos');
    onColappsed();
  };

  let drawerWidth = '75%';
  if (breakpoint?.md) {
    drawerWidth = '40%';
  }
  if (breakpoint?.lg) {
    drawerWidth = '30%';
  }
  if (breakpoint?.xl) {
    drawerWidth = '25%';
  }
  if (breakpoint?.xxl) {
    drawerWidth = '20%';
  }

  const items = [
    {
      key: 'dashboard',
      label: (
        <DrawerItem
          label="Dashboard"
          icon={<StHomeOutlined />}
          path="/dashboard"
          onClick={onClickDash}
          onClickPlans={onClickPlans}
          {...dataTestIdHandler(dataTestPrefix, 'dashboard-button')}
        />
      ),
    },
    {
      key: 'registro-de-implantacao',
      label: (
        <DrawerItem
          label="Registro de Implantação"
          icon={<StFormOutlined />}
          path="/registro-de-implantacao"
          onClick={onClickRegistroImplantacao}
          onClickPlans={onClickPlans}
          {...dataTestIdHandler(dataTestPrefix, 'briefing-button')}
        />
      ),
    },
    {
      key: 'manutencao-cnpj',
      label: (
        <DrawerItem
          label="Manutenção de CNPJs e adquirentes"
          icon={<StToolOutlined />}
          path="/manutencao-cnpj"
          onClick={onClickManutencao}
          onClickPlans={onClickPlans}
          {...dataTestIdHandler(dataTestPrefix, 'cnpj-maintenance-button')}
        />
      ),
    },
    {
      key: 'taxas',
      label: (
        <DrawerItem
          label="Cadastro de taxas"
          icon={<StPercentageOutlined />}
          path="/taxas"
          onClick={onClickFees}
          onClickPlans={onClickPlans}
          blocked={!isFeatureAvailable({ key: 'menu-fees' })}
          blockedTooltipText="Com todas as suas taxas cadastradas, saiba de uma vez por todas se seus pagamentos estão sendo realizados corretamente."
          {...dataTestIdHandler(dataTestPrefix, 'fees-button')}
        />
      ),
    },
    {
      key: 'importacao-arquivos',
      label: (
        <DrawerItem
          label="Importação de OFX"
          icon={<StUploadOutlined />}
          path="/importacao-arquivos"
          onClick={onClickImportacao}
          onClickPlans={onClickPlans}
          blocked={!isFeatureAvailable({ key: 'menu-importFiles' })}
          blockedTooltipText="Importe seus dados bancários de forma rápida utilizando o extrato OFX. Diga adeus a conciliação bancária manual e transforme horas de análise em minutos de conferência!"
          {...dataTestIdHandler(dataTestPrefix, 'import-files-button')}
        />
      ),
    },
    isConciler() && {
      key: 'banking',
      label: (
        <DrawerItem
          label="Ver ofertas"
          icon={<StNotificationOutlined />}
          path="/banking"
          onClick={onClickOfertas}
          onClickPlans={onClickPlans}
          {...dataTestIdHandler(dataTestPrefix, 'banking-button')}
        />
      ),
    },
    {
      key: 'close',
      label: (
        <DrawerItem
          label="Fechar"
          icon={<StDoubleLeftOutlined />}
          path="#"
          onClick={closeMenus}
          onClickPlans={onClickPlans}
          {...dataTestIdHandler(dataTestPrefix, 'close-button')}
        />
      ),
    },
  ];

  return (
    <StLeftMenuDrawer
      closable={false}
      open={collapsed}
      placement="left"
      onClose={onColappsed}
      width={drawerWidth}
      contentWrapperStyle={{
        position: 'fixed',
        top: '40px',
        bottom: 'unset',
        left: 0,
        boxShadow: 'none',
        height: '100%',
      }}
      bodyStyle={leftDrawerBodyStyle}
      {...others}
    >
      {!permissionsLoading ? <StLeftMenu items={items} /> : <StSpin />}
    </StLeftMenuDrawer>
  );
};
LeftDrawerOptions.propTypes = {
  collapsed: PropType.bool.isRequired,
  onColappsed: PropType.func.isRequired,
  userFirstName: PropType.string.isRequired,
};

export default LeftDrawerOptions;
