import React, { useContext } from 'react';
import plans from 'constants/plans';
import { plansFeatures } from 'constants/plansFeatures';
import {
  BackToTopButton,
  Footer,
  PlanComparisonContainer,
  PlanComparisonTable,
  PlanComparisonTitleContainer,
  StCheckOutlined,
  StCloseOutlined,
  StCol,
  StCustomTh,
  StUpOutlined,
} from './styled';
import { usePlansFeaturesPermissions } from 'utils/hooks/usePlansFeaturesPermissions';
import { PermissionsContext } from 'routes/PrivateRoute';
import { dataTestIdHandler } from 'helpers/dataTestIdHandler';

export const PlanComparison = ({ dataTestPrefix }) => {
  const { isFeatureAvailable } = usePlansFeaturesPermissions();

  const { permissions } = useContext(PermissionsContext);
  const planIdPermission = permissions?.plan_id;
  const isMostChosenTagVisible = planIdPermission !== plans.ADMINISTRE_PLUS;

  const assetsBaseURL = process.env.REACT_APP_AWS_V4_ASSETS;
  const iconConcil = `${assetsBaseURL}/img/iconConcil.png`;

  const PlanComparisonFeaturePermission = ({
    planId = undefined,
    isAvailable = false,
    planPermission = [],
  }) => {
    if (!isAvailable) {
      return (
        <td className="not-available">
          <span>Em breve</span>
        </td>
      );
    }

    const hasPlanPermission = (id, permission) => permission.includes(id);

    return (
      <td>
        {hasPlanPermission(planId, planPermission) ? (
          <StCheckOutlined />
        ) : (
          <StCloseOutlined />
        )}
      </td>
    );
  };

  const handleBackToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <StCol span={24}>
      <PlanComparisonContainer
        {...dataTestIdHandler(dataTestPrefix, 'plan-comparison-container')}
      >
        <PlanComparisonTitleContainer
          {...dataTestIdHandler(
            dataTestPrefix,
            'plan-comparison-title-container'
          )}
        >
          <img src={iconConcil} alt="Logotipo do Concil Card" />
          <div>
            <strong>Precisa de ajuda para escolher o plano certo?</strong>
            <span>Compare as funcionalidades de cada plano</span>
          </div>
        </PlanComparisonTitleContainer>
        <PlanComparisonTable
          {...dataTestIdHandler(dataTestPrefix, 'plan-comparison-table')}
        >
          <thead>
            <tr>
              <th>Funcionalidades</th>
              {isFeatureAvailable({
                key: 'plans-free-plan-column',
              }) && <StCustomTh plan="free">Free</StCustomTh>}

              <StCustomTh plan="controle">Controle</StCustomTh>

              <StCustomTh
                plan="gerencie"
                isMostChosenTagVisible={isMostChosenTagVisible}
              >
                Gerencie
              </StCustomTh>

              <StCustomTh plan="plus">Plus</StCustomTh>
            </tr>
          </thead>
          <tbody>
            {plansFeatures.map(({ name, isAvailable, planPermission }) => (
              <tr key={name}>
                <td>{name}</td>
                {isFeatureAvailable({
                  key: 'plans-free-plan-column',
                }) && (
                  <PlanComparisonFeaturePermission
                    planId={plans.FREE}
                    isAvailable={isAvailable}
                    planPermission={planPermission}
                  />
                )}

                <PlanComparisonFeaturePermission
                  planId={plans.CONTROLE}
                  isAvailable={isAvailable}
                  planPermission={planPermission}
                />
                <PlanComparisonFeaturePermission
                  planId={plans.GERENCIE}
                  isAvailable={isAvailable}
                  planPermission={planPermission}
                />
                <PlanComparisonFeaturePermission
                  planId={plans.ADMINISTRE_PLUS}
                  isAvailable={isAvailable}
                  planPermission={planPermission}
                />
              </tr>
            ))}
          </tbody>
        </PlanComparisonTable>
        <Footer
          {...dataTestIdHandler(dataTestPrefix, 'plan-comparison-footer')}
        >
          <div>
            <span>Gostou dos nossos planos? </span>
            <span>
              Saiba mais sobre nossas ofertas falando com nossos especialistas
            </span>
          </div>

          <BackToTopButton
            onClick={handleBackToTop}
            {...dataTestIdHandler(
              dataTestPrefix,
              'plan-comparison-back-to-top-button'
            )}
          >
            <span>Falar com especialistas</span>
            <StUpOutlined />
          </BackToTopButton>
        </Footer>
      </PlanComparisonContainer>
    </StCol>
  );
};
