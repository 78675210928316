import React, { useState, useEffect } from 'react';
import PropType from 'prop-types';
import skins from 'constants/skins';
import customHistory from 'helpers/history';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, Divider, Spin } from 'antd';
import { logout } from 'store/ducks/logout/actions';
import { UserInviteDrawer } from 'commons/Header/components';
import { listOfPlansStart } from 'store/ducks/listOfPlans/actions';
import { StLinkUserMenuItem, StNotificationsMask } from 'commons/Header/styled';
import { colors } from 'styles/colors';
import { EllipsisText } from 'commons/EllipsisText';
import { dataTestIdHandler } from 'helpers/dataTestIdHandler';
import { usePlansFeaturesPermissions } from 'utils/hooks/usePlansFeaturesPermissions';
import {
  isAdmin,
  isClient,
  isConciler,
  isOperational,
  isSuperAdmin,
} from 'helpers/authorizationService';
import {
  LogoutOutlined,
  UserAddOutlined,
  AuditOutlined,
  ShopOutlined,
  ExperimentOutlined,
  AimOutlined,
  ControlOutlined,
  SyncOutlined,
  RadarChartOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import {
  StBranchDetailContainer,
  StBranchLogo,
  StBranchName,
  StButton,
  StExitBtn,
  StExitBtnContainer,
  StUserDetailContainer,
  StUserMenuButton,
  StUserMenuContainer,
  StUserMenuLink,
} from './styled';

const UserMenu = ({
  collapsed,
  onColappsed,
  userFullName,
  userEmail,
  branchName,
  logoLink,
  skinId,
  ...others
}) => {
  const dispatch = useDispatch();
  const { isFeatureAvailable } = usePlansFeaturesPermissions();
  const [userInviteVisible, toggleUserInvite] = useState(false);
  const listOfClientsLoading = useSelector(
    (state) => state.listOfClients?.loading
  );

  const assetsBaseURL = process.env.REACT_APP_AWS_V4_ASSETS;
  const seePlansIconUri = `${assetsBaseURL}/svg/shineOutlined.svg`;

  const dataTestPrefix = 'user-menu';
  const { CONCIL_REVENDA } = skins;

  useEffect(() => {
    dispatch(listOfPlansStart());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLogout = () => {
    dispatch(logout());
    onColappsed();
  };

  const onClickOFXParameters = () => {
    customHistory.push('/regras-ofx');
    onColappsed();
  };

  const onClickGerenciarUsuarios = () => {
    customHistory.push('/gerenciar-usuarios');
    onColappsed();
  };

  const onClickGerenciarEmpresas = () => {
    customHistory.push('/gerenciar-empresas');
    onColappsed();
  };

  const onClickOnboardingManagement = () => {
    customHistory.push('/gerenciar-implantacao');
    onColappsed();
  };

  const onClickRetroactiveConciliation = () => {
    customHistory.push('/conciliacao-retroativa');
    onColappsed();
  };

  const onClickRadar = () => {
    customHistory.push('/radar');
    onColappsed();
  };

  const onClickConsultarAcessos = () => {
    customHistory.push('/consultar-acessos');
    onColappsed();
  };

  const onClickRegrasConciliacao = () => {
    customHistory.push('/regras-conciliacao');
    onColappsed();
  };

  const onClickSeePlans = () => {
    customHistory.push('/planos');
    onColappsed();
  };

  const AccessManagement = () => {
    return (
      <>
        <Typography.Text
          style={{
            fontWeight: 500,
            display: 'block',
            paddingLeft: '20px',
            paddingTop: '10px',
            color: colors.gray8,
          }}
        >
          Usuários e Empresas
        </Typography.Text>

        {!(isClient() && isOperational()) ? (
          <StButton
            onClick={() => {
              toggleUserInvite(true);
            }}
            icon={<UserAddOutlined style={{ fontSize: '21px' }} />}
            {...dataTestIdHandler(dataTestPrefix, 'user-invite-button')}
          >
            Convidar usuários
          </StButton>
        ) : (
          <></>
        )}

        {userInviteVisible && (
          <UserInviteDrawer
            visible={userInviteVisible}
            onClose={() => {
              toggleUserInvite(false);
            }}
            onColappsed={onColappsed}
          />
        )}

        {!(isClient() && isOperational()) ? (
          <StButton
            icon={<AuditOutlined style={{ fontSize: '21px' }} />}
            onClick={onClickGerenciarUsuarios}
            {...dataTestIdHandler(dataTestPrefix, 'user-management-button')}
          >
            <StLinkUserMenuItem to="/gerenciar-usuarios">
              Gerenciar usuários
            </StLinkUserMenuItem>
          </StButton>
        ) : (
          <></>
        )}

        {(isAdmin() || isSuperAdmin()) && isConciler() ? (
          <StButton
            icon={<ShopOutlined style={{ fontSize: '21px' }} />}
            onClick={onClickGerenciarEmpresas}
            {...dataTestIdHandler(dataTestPrefix, 'branch-management-button')}
          >
            <StLinkUserMenuItem to="/gerenciar-empresas">
              Gerenciar empresas
            </StLinkUserMenuItem>
          </StButton>
        ) : (
          <></>
        )}

        {(isAdmin() || isSuperAdmin()) && isConciler() ? (
          <StButton
            icon={<ExperimentOutlined style={{ fontSize: '21px' }} />}
            onClick={onClickConsultarAcessos}
            {...dataTestIdHandler(
              dataTestPrefix,
              'general-user-management-button'
            )}
          >
            <StLinkUserMenuItem to="/consultar-acessos">
              Consultar acessos
            </StLinkUserMenuItem>
          </StButton>
        ) : (
          <></>
        )}
      </>
    );
  };

  const InternalManagement = () => {
    return (
      <>
        {(isAdmin() || isSuperAdmin()) && isConciler() && (
          <>
            <Divider style={{ margin: 0 }} />

            <Typography.Text
              style={{
                fontWeight: 500,
                display: 'block',
                paddingLeft: '20px',
                paddingTop: '10px',
                color: colors.gray8,
                margin: '3px 0px',
              }}
            >
              Administração interna
            </Typography.Text>
          </>
        )}

        {(isAdmin() || isSuperAdmin()) && isConciler() && (
          <StButton
            icon={<SettingOutlined style={{ fontSize: '21px' }} />}
            disabled // TODO: remove this after the actual page is created
            onClick={onClickOnboardingManagement}
            {...dataTestIdHandler(
              dataTestPrefix,
              'onboarding-management-button'
            )}
          >
            <StLinkUserMenuItem to="/gerenciar-implantacao">
              Gerenciar implantação
            </StLinkUserMenuItem>
          </StButton>
        )}

        {(isAdmin() || isSuperAdmin()) && isConciler() && (
          <StButton
            icon={<SyncOutlined style={{ fontSize: '21px' }} />}
            onClick={onClickRetroactiveConciliation}
            {...dataTestIdHandler(
              dataTestPrefix,
              'retroactive-conciliation-button'
            )}
          >
            <StLinkUserMenuItem to="/conciliacao-retroativa">
              Conciliação
            </StLinkUserMenuItem>
          </StButton>
        )}
        {(isAdmin() || isSuperAdmin()) && isConciler() ? (
          <StButton
            icon={<RadarChartOutlined style={{ fontSize: '21px' }} />}
            onClick={onClickRadar}
            {...dataTestIdHandler(dataTestPrefix, 'radar-button')}
          >
            <StLinkUserMenuItem to="/radar">Radar</StLinkUserMenuItem>
          </StButton>
        ) : (
          <></>
        )}
        {isConciler() ? (
          <StButton
            icon={<ControlOutlined style={{ fontSize: '21px' }} />}
            onClick={onClickRegrasConciliacao}
            {...dataTestIdHandler(
              dataTestPrefix,
              'reconciliation-rules-button'
            )}
          >
            <StLinkUserMenuItem to="/regras-conciliacao">
              Regras de conciliação
            </StLinkUserMenuItem>
          </StButton>
        ) : (
          <></>
        )}
        {isConciler() || isSuperAdmin() ? (
          <StButton
            icon={<AimOutlined style={{ fontSize: '21px' }} />}
            onClick={onClickOFXParameters}
            {...dataTestIdHandler(dataTestPrefix, 'ofx-parameters-button')}
          >
            <StLinkUserMenuItem to="/regras-ofx">Regras OFX</StLinkUserMenuItem>
          </StButton>
        ) : (
          <></>
        )}
      </>
    );
  };

  return (
    <div {...others}>
      {collapsed && (
        <>
          <StNotificationsMask
            notificationsAreVisible={collapsed}
            onClick={onColappsed}
          />

          <StUserMenuContainer
            {...dataTestIdHandler(dataTestPrefix, 'container')}
          >
            {listOfClientsLoading ? (
              <Spin />
            ) : (
              <>
                {userFullName && userEmail && (
                  <StUserDetailContainer
                    {...dataTestIdHandler(
                      dataTestPrefix,
                      'name-email-container'
                    )}
                  >
                    <EllipsisText text={userFullName} length={26} />
                    <EllipsisText text={userEmail} length={30} />
                  </StUserDetailContainer>
                )}

                <StBranchDetailContainer
                  {...dataTestIdHandler(dataTestPrefix, 'branch-container')}
                >
                  {branchName && (
                    <StBranchName
                      {...dataTestIdHandler(dataTestPrefix, 'branch-name')}
                    >
                      <EllipsisText
                        text={branchName.toUpperCase()}
                        length={50}
                      />
                    </StBranchName>
                  )}

                  <StBranchLogo
                    {...dataTestIdHandler(dataTestPrefix, 'plan-logo')}
                  >
                    <img src={logoLink} alt="logo do plano" />
                  </StBranchLogo>
                </StBranchDetailContainer>

                {skinId !== CONCIL_REVENDA && (
                  <StUserMenuButton
                    icon={<img src={seePlansIconUri} alt="Ícone de brilho" />}
                    onClick={onClickSeePlans}
                    {...dataTestIdHandler(dataTestPrefix, 'see-plans-button')}
                  >
                    <StUserMenuLink to="/planos">
                      Consultar planos
                    </StUserMenuLink>
                  </StUserMenuButton>
                )}

                {(isConciler() ||
                  isFeatureAvailable({ key: 'userMenu-accessManagement' })) && (
                  <AccessManagement />
                )}

                <InternalManagement />

                <Divider style={{ marginTop: '0px', marginBottom: '8px' }} />

                <StExitBtnContainer>
                  <StExitBtn
                    {...dataTestIdHandler(dataTestPrefix, 'logout-button')}
                    icon={
                      <LogoutOutlined
                        style={{ fontSize: '21px', marginLeft: '5px' }}
                      />
                    }
                    onClick={handleLogout}
                    block
                  >
                    Sair
                  </StExitBtn>
                </StExitBtnContainer>
              </>
            )}
          </StUserMenuContainer>
        </>
      )}
    </div>
  );
};
UserMenu.propTypes = {
  collapsed: PropType.bool.isRequired,
  onColappsed: PropType.func.isRequired,
};

export default UserMenu;
