import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getPermissions } from 'services/auth';
import { removeLocalStorage } from 'helpers/sessionService';
import errorHandler from 'helpers/errorHandler';

export const usePermissions = () => {
  const navigate = useNavigate();
  const [permissions, setPermissions] = useState({});
  const [loading, setLoading] = useState(false);

  const fetchPermissions = () => {
    setLoading(true);

    getPermissions()
      .then((data) => setPermissions(data))
      .catch(() => {
        errorHandler(
          'Ocorreu um erro ao buscar os dados do usuário, por favor, atualize a página'
        );
        removeLocalStorage();
        navigate('/');
      })
      .finally(() => setLoading(false));
  };

  return {
    loading,
    permissions,
    fetchPermissions,
  };
};
