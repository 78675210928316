// TODO: Remove camelcase eslint rule
/* eslint-disable camelcase */
import { api, apiGateway } from './api';

const transformer = (response, permissions) => {
  const { data } = response;
  const { data: permissionsData } = permissions;
  return { data, permissionsData };
};

const putChangeStoreFromAPI = async ({ user_id, client_id }) => {
  const response = await api().put('/default', { user_id, client_id });
  const permissionsData = await apiGateway().post('/profile/post');
  return transformer(response.data, permissionsData.data);
};

export default putChangeStoreFromAPI;
