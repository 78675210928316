import React, { useContext } from 'react';
import plans from 'constants/plans';
import { PriceCards, StCol } from './styled';
import { PriceCard } from '../PriceCard/PriceCard';
import { plansDetails } from 'constants/plansDetails';
import { PermissionsContext } from 'routes/PrivateRoute';
import { dataTestIdHandler } from 'helpers/dataTestIdHandler';

export const PlansContainer = ({ dataTestPrefix }) => {
  const { permissions } = useContext(PermissionsContext);
  const planId = permissions?.plan_id;
  const isCurrentPlan = (id) => id === planId;
  const isMostChosenTagVisible = planId !== plans.ADMINISTRE_PLUS;

  const filterPlans = (_plansDetails, _planId) => {
    const notPagGratisOrFree = ({ id }) =>
      id !== plans.PAG_GRATIS && id !== plans.FREE;
    const notPagGratis = ({ id }) => id !== plans.PAG_GRATIS;

    if (_planId !== plans.PAG_GRATIS && _planId !== plans.FREE) {
      return _plansDetails.filter(notPagGratisOrFree);
    }
    if (_planId === plans.FREE) {
      return _plansDetails.filter(notPagGratis);
    }
    return [..._plansDetails];
  };

  const plansDetailsFiltered = filterPlans(plansDetails, planId);

  return (
    <StCol
      span={24}
      $tag={isMostChosenTagVisible}
      {...dataTestIdHandler(dataTestPrefix, 'price-cards-container')}
    >
      <PriceCards>
        {plansDetailsFiltered.map((plan) => (
          <PriceCard
            key={plan?.id}
            plan={plan}
            currentPlan={isCurrentPlan(plan?.id)}
            isMostChosenTagVisible={isMostChosenTagVisible}
            {...dataTestIdHandler(
              dataTestPrefix,
              `price-card-${plan?.name}`.toLowerCase()
            )}
          />
        ))}
      </PriceCards>
    </StCol>
  );
};
