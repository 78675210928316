import React from 'react';
import BlockedFeaturePopover from 'commons/BlockedFeaturePopover/BlockedFeaturePopover';
import { Button, Dropdown, Tooltip } from 'antd';
import { DownloadOutlined, LoadingOutlined } from '@ant-design/icons';
import { MAX_CSV_ROWS, MAX_EXCEL_ROWS } from 'constants/general';
import { dataTestIdHandler } from 'helpers/dataTestIdHandler';

const ExportDropdown = ({
  blocked = false,
  blockedTooltipText = 'Exporte este e os demais relatórios e tenha uma visualização completa.',
  loading = false,
  visibleExcel = true,
  visibleCSV = true,
  disableExcel = false,
  disableCSV = false,
  buttonType = 'primary',
  dataTestPrefix = 'exportDropdown',
  setDropdownOption = () => {},
  ...others
}) => {
  const excelToolTipTitle = disableExcel
    ? `Sua solicitação excedeu o limite permitido do arquivo de ${MAX_EXCEL_ROWS.toString().replace(
        /\B(?=(\d{3})+(?!\d))/g,
        '.'
      )} itens. Diminua o período selecionado.`
    : 'Esta opção pode demorar mais dependendo da quantidade de dados.';
  const csvToolTipTitle = disableCSV
    ? `Sua solicitação excedeu o limite permitido do arquivo de ${MAX_CSV_ROWS.toString().replace(
        /\B(?=(\d{3})+(?!\d))/g,
        '.'
      )} itens. Diminua o período selecionado.`
    : '';

  const items = [
    visibleExcel && {
      key: 'excel',
      label: (
        <Tooltip title={excelToolTipTitle} placement="topRight">
          Excel
        </Tooltip>
      ),
      disabled: disableExcel || loading,
      onClick: () => setDropdownOption('excel'),
      ...dataTestIdHandler(dataTestPrefix, 'export-option-excel'),
    },
    visibleCSV && {
      key: 'csv',
      label: (
        <Tooltip title={csvToolTipTitle} placement="topRight">
          CSV
        </Tooltip>
      ),
      disabled: disableCSV || loading,
      onClick: () => setDropdownOption('csv'),
      ...dataTestIdHandler(dataTestPrefix, 'export-option-csv'),
    },
  ];

  return (
    <BlockedFeaturePopover
      visible={blocked}
      placement="left"
      tooltipText={blockedTooltipText}
    >
      {blocked ? (
        <Button
          type={buttonType}
          icon={loading ? <LoadingOutlined /> : <DownloadOutlined />}
          {...others}
        />
      ) : (
        <Dropdown menu={{ items }} disabled={loading}>
          <Button
            type={buttonType}
            icon={loading ? <LoadingOutlined /> : <DownloadOutlined />}
            {...others}
          />
        </Dropdown>
      )}
    </BlockedFeaturePopover>
  );
};

export default ExportDropdown;
