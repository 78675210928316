export const plansFeatures = [
  {
    name: 'Visão das vendas capturadas por adquirente',
    isAvailable: true,
    planPermission: [1, 2, 4, 7],
  },
  {
    name: 'Visão das vendas por produto, bandeira e parcela',
    isAvailable: true,
    planPermission: [1, 2, 4, 7],
  },
  {
    name: 'Visão dos valores a receber',
    isAvailable: true,
    planPermission: [1, 2, 4, 7],
  },
  {
    name: 'Atendimento por chamado, e-mail ou chat',
    isAvailable: true,
    planPermission: [1, 2, 4, 7],
  },
  {
    name: 'Atendimento telefônico',
    isAvailable: true,
    planPermission: [1, 2, 4],
  },
  {
    name: 'Calendário ilimitado',
    isAvailable: true,
    planPermission: [1, 2, 4],
  },
  {
    name: 'Radar - Resumo semanal e mensal por e-mail',
    isAvailable: true,
    planPermission: [1, 2, 4],
  },
  {
    name: 'Controle de cancelamentos e chargeback',
    isAvailable: true,
    planPermission: [1, 2, 4],
  },
  {
    name: 'Controle de aluguel de POS e ajustes',
    isAvailable: true,
    planPermission: [1, 2, 4],
  },
  {
    name: 'Visão dos recebimentos bancários',
    isAvailable: true,
    planPermission: [1, 2, 4],
  },
  {
    name: 'Visão dos recebimentos por banco',
    isAvailable: true,
    planPermission: [1, 2, 4],
  },
  {
    name: 'Aging - Visão retroativa dos valores a receber',
    isAvailable: true,
    planPermission: [1, 2, 4],
  },
  {
    name: 'Gestão do PIX',
    isAvailable: true,
    planPermission: [1, 2, 4],
  },
  {
    name: 'Cadastro e conciliação de taxas administrativas',
    isAvailable: true,
    planPermission: [1, 2, 4],
  },
  {
    name: 'Convidar usuários',
    isAvailable: true,
    planPermission: [1, 2, 4],
  },
  {
    name: 'Conciliação de pagamentos',
    isAvailable: true,
    planPermission: [1, 2, 4],
  },
  {
    name: 'Conciliação bancária',
    isAvailable: true,
    planPermission: [2, 4],
  },
  {
    name: 'Importação de OFX',
    isAvailable: true,
    planPermission: [2, 4],
  },
  {
    name: 'Conciliação de vendas',
    isAvailable: true,
    planPermission: [4],
  },
  {
    name: 'Treinamento personalizado',
    isAvailable: true,
    planPermission: [4],
  },
  {
    name: 'Integração de API',
    isAvailable: true,
    planPermission: [4],
  },
  {
    name: 'Acompanhamento de um gerente de sucesso',
    isAvailable: true,
    planPermission: [4],
  },
  {
    name: 'Simulação de antecipação',
    isAvailable: false,
    planPermission: [],
  },
  {
    name: 'Menu de Inteligência',
    isAvailable: false,
    planPermission: [],
  },
];
