import skins from 'constants/skins';
import scopes from 'constants/scopes';
import { contextRoutes } from 'constants/contextRoutes';
import { privateRoutes } from 'constants/privateRoutes';

export const getContextRoutes = ({ planId = undefined }) => {
  return contextRoutes[planId];
};

export const getAccessRoutes = ({
  planId = undefined,
  scopeId = undefined,
  skinId = undefined,
}) => {
  if (!planId && !scopeId && !skinId) return [];

  const { accessRoutes = [] } = getContextRoutes({ planId });

  // TO DO: Change validation to API
  if (skinId === skins.CONCIL_REVENDA) {
    return accessRoutes?.filter((route) => route !== '/planos');
  }

  if (scopeId === scopes.CONCIL) return [...privateRoutes];

  return accessRoutes;
};

export const getDefaultRoute = ({ planId = undefined }) => {
  if (!planId) return '';
  const { defaultRoute = '' } = getContextRoutes({ planId });
  return defaultRoute;
};
