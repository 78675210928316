/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-expressions */
/* eslint-disable arrow-parens,jsx-a11y/alt-text */
import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useContext,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Col,
  Row,
  Button,
  Collapse,
  BackTop,
  Card,
  Typography,
  Spin,
} from 'antd';
import { columns } from '../utils/columns';
import { getClientAcquirerStart } from '../../../../store/ducks/clientAcquirer/actions';
import {
  acquirerMerchantsStart,
  acquirerStart,
} from '../../../../store/ducks/acquirer/actions';
import { optinStart } from 'store/ducks/optin/optin/actions';
import { optinRequestStart } from 'store/ducks/optin/optinRequest/actions';
import { StListSkeletonContainer } from 'components/CnpjMaintenance/styled';
import { StCollapse, StSubTitleItem, StTable, StTitleItem } from './styled';
import { CnpjModal } from '../CnpjModal';
import { getSessionClientId } from 'helpers/sessionService';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { ErrorCard } from 'commons/ErrorCard';
import { Empty } from 'commons/Empty';
import { colors } from 'styles/colors';
import useAcquirerList from 'components/CnpjMaintenance/hooks/useAcquirerList';
import { StSpin } from '../CnpjStoresList/styled';
import {
  listMerchantsStart,
  resetListMerchantsStart,
} from 'store/ducks/cnpjMaintenance/merchants/listMerchants/actions';
import { capitalizeFirstLetter } from 'helpers/capitalizeFirstLetter';
import { CnpjMaintenanceContext } from 'components/CnpjMaintenance/CnpjMaintenance';

const CnpjAcquirersList = ({
  searchFilter,
  isNextButtonLocked,
  setIsNextButtonLocked,
}) => {
  const { merchantHasPosted } = useContext(CnpjMaintenanceContext);

  const dispatch = useDispatch();
  const clientAcquirersData = useSelector(
    (state) => state.clientAcquirer.clientAcquirers
  );
  const acquirerData = useSelector((state) => state.acquirer.acquirers);

  const [addModalVisibility, setAddModalVisibility] = useState(false);
  const [listClientAcquirers, setListClientAcquirers] = useState([]);
  const [listAcquirers, setListAcquirers] = useState([]);
  const [clickedMerchant, setClickedMerchant] = useState({});
  const optinUrls = useSelector((state) => state.optinUrls?.urls);
  const [triggeredRequest, setTriggeredRequest] = useState(false);

  const optinData = useSelector((state) => state.optin?.data);
  const optinRequestLoading = useSelector(
    (state) => state.optinRequest?.loading
  );
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);

  const { Panel } = Collapse;

  const deleteMerchantSuccess = useSelector(
    (state) => state.deleteMerchant.success
  );

  const openAddModal = () => {
    setAddModalVisibility(true);
  };
  const closeAddModal = () => {
    setAddModalVisibility(false);
  };

  useEffect(() => {
    dispatch(getClientAcquirerStart());
    dispatch(acquirerStart({}));
  }, [dispatch]);

  useEffect(() => {
    forceUpdate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optinRequestLoading]);

  useEffect(() => {
    if (acquirerData) {
      acquirerData.forEach((acquirer) => {
        if (
          acquirer?.name === 'CIELO' ||
          acquirer?.name === 'REDE' ||
          acquirer?.name === 'STONE'
        ) {
          const clientId = getSessionClientId();
          dispatch(
            optinStart({
              page: 1,
              perPage: 100,
              clientId: clientId,
              acquirer: acquirer?.name,
            })
          );
          if (acquirer?.name === 'CIELO') {
            const locationUrl = new URL(window.location.href);
            const cieloCode = locationUrl.searchParams.get('code');
            const redirectedMerchant = locationUrl.searchParams
              .get('state')
              ?.replace('CONCIL', '');

            if (cieloCode) {
              // trigger safety
              if (!triggeredRequest) {
                dispatch(
                  optinRequestStart({
                    code: cieloCode,
                    merchant: redirectedMerchant,
                    acquirer: acquirer?.name,
                  })
                );
                setTriggeredRequest(true);
              }
            }
          }
        }
      });
    }
    dispatch(acquirerMerchantsStart());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acquirerData]);

  // The useEffect code below must be refactored!!!!!
  useEffect(() => {
    if (clientAcquirersData?.acquirers) {
      clientAcquirersData.acquirers.forEach((acquirer) => {
        if (optinData[acquirer.name]) {
          optinData[acquirer.name].forEach((optinMerchant) => {
            acquirer.merchants.forEach((merchant) => {
              if (optinMerchant?.merchant_code === merchant?.merchant_code) {
                merchant.status = optinMerchant?.status;
                merchant.update_datetime = optinMerchant?.update_datetime;
              }
            });
          });
        }
      });
    }
    forceUpdate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optinData]);

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    optinUrls?.forEach((merchant) => {
      if (
        clickedMerchant?.merchant_code === merchant?.merchant_code &&
        clickedMerchant?.store_id === merchant?.store_id
      ) {
        const merchantUrl = new URL(merchant?.url);
        const redirectUri = merchantUrl.searchParams.get('redirect_uri');
        if (
          window.location.hostname === 'localhost' ||
          window.location.hostname === '127.0.0.1'
        ) {
          const localhostUri = redirectUri.replace(
            'https://www.concildesenvolvedores.com',
            'http://localhost:3001'
          );
          merchantUrl.searchParams.set('redirect_uri', localhostUri);
        }
        window.location.href = merchantUrl.href;
      }
    });
  }, [optinUrls, clickedMerchant]);

  useEffect(() => {
    if (acquirerData && !listAcquirers) {
      setListAcquirers(acquirerData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acquirerData]);

  useEffect(() => {
    setListClientAcquirers(clientAcquirersData?.acquirers);
  }, [clientAcquirersData]);

  useEffect(() => {}, [listClientAcquirers]);

  const [page, setPage] = useState(1);
  // eslint-disable-next-line no-unused-vars
  const [perPage, setPerPage] = useState(10);
  const [merchantCount, setMerchantCount] = useState(0);

  const {
    isLoading: isAcquirerLoading,
    isError: isAcquirerError,
    acquirers,
    refreshAcquirers,
    metaAcquirerListData,
    hasLoadedAcquirers,
  } = useAcquirerList(searchFilter);

  const { merchant_code: merchantCodeFromMeta } = metaAcquirerListData;

  const [currentActivePanel, setCurrentActivePanel] = useState('');
  const [acquirerSelected, setAcquirerSelected] = useState('');

  const handlePanelChange = async (key) => {
    if (key) {
      setPage(1);
      setCurrentActivePanel(key);
    } else {
      setCurrentActivePanel('');
    }
  };

  const isLoadingMerchants = useSelector(
    (state) => state.listMerchants.loading
  );
  const merchants = useSelector((state) => state.listMerchants?.merchants);
  const merchantTotal = useSelector((state) => state?.listMerchants?.total);
  const hasMoreMerchants = useSelector(
    (state) => state?.listMerchants?.hasMore
  );

  useEffect(() => {
    if (page > 1) {
      dispatch(
        listMerchantsStart({
          acquirerId: currentActivePanel,
          merchantCode: merchantCodeFromMeta,
          page,
          perPage: 10,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    if (hasLoadedAcquirers && currentActivePanel) {
      dispatch(
        listMerchantsStart({
          acquirerId: currentActivePanel,
          merchantCode: merchantCodeFromMeta,
          page: 1,
          perPage: 10,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentActivePanel]);

  const AcquirerHeader = ({ acquirer }) => {
    const acquirerName = acquirer?.acquirer;
    const acquirerDetails = acquirer?.detail;
    return (
      <>
        <Row
          style={{
            padding: '24px',
            backgroundColor: colors.gray1,
            borderRadius: '4px 4px 0px 0px',
          }}
        >
          <Col
            span={12}
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: 16,
            }}
          >
            <img
              src={acquirerDetails?.icon}
              width="32px"
              height="32px"
              alt={`Ícone da ${acquirerName}`}
            />
            <StTitleItem>{capitalizeFirstLetter(acquirerName)}</StTitleItem>
            <StSubTitleItem>
              {acquirerDetails?.count} estabelecimento(s) cadastrado(s)
            </StSubTitleItem>
          </Col>
          <Col
            span={12}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'end ',
            }}
          >
            <StSubTitleItem>
              <Button
                style={{ borderRadius: '4px' }}
                onClick={(e) => {
                  e.stopPropagation();
                  openAddModal();
                  setAcquirerSelected(acquirerDetails?.acquirer_id);
                }}
              >
                Incluir estabelecimento
              </Button>
            </StSubTitleItem>
          </Col>
        </Row>
      </>
    );
  };

  const observer = useRef();
  const lastMerchantElementRef = useCallback(
    (node) => {
      if (isLoadingMerchants) return;

      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMoreMerchants) {
          setPage((prev) => prev + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoadingMerchants, hasMoreMerchants]
  );

  const TableFooter = () => {
    return (
      <Row
        style={{
          padding: '8px 0',
          backgroundColor: colors.gray2,
        }}
      >
        <Col
          span={24}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {isLoadingMerchants ? (
            <Spin size="small" />
          ) : (
            <Typography.Text>
              {`Exibindo ${merchantCount} de ${merchantTotal} ${
                merchantTotal === 1 || merchantTotal === 0 ? 'item' : 'itens'
              }.`}
            </Typography.Text>
          )}
        </Col>
      </Row>
    );
  };

  useEffect(() => {
    setMerchantCount(merchants.length);
  }, [merchants]);

  useEffect(() => {
    if (deleteMerchantSuccess) {
      setPage(1);
      refreshAcquirers();
      handlePanelChange('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteMerchantSuccess]);

  useEffect(() => {
    setCurrentActivePanel('');
    dispatch(resetListMerchantsStart());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchFilter]);

  function refreshAcquirersList() {
    setPage(1);
    refreshAcquirers();
    handlePanelChange('');
  }

  useEffect(() => {
    if (merchantHasPosted) {
      refreshAcquirersList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchantHasPosted]);

  // Render Error Card
  if (isAcquirerError) {
    return (
      <Row style={{ marginTop: '192px', justifyContent: 'center' }}>
        <Col lg={10} md={18}>
          <Card style={{ borderRadius: 4 }} loading={isAcquirerLoading}>
            <ErrorCard
              title="Dados indisponíveis"
              reason="Não conseguimos buscar os dados, mas já estamos trabalhando para normalizar a situação."
              recommendation="Tente atualizar a página em alguns instantes."
            />
          </Card>
        </Col>
      </Row>
    );
  }

  return (
    <>
      {acquirers?.length > 0 && !isAcquirerLoading ? (
        <>
          <StCollapse
            accordion
            bordered={false}
            destroyInactivePanel
            ghost
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
            onChange={handlePanelChange}
          >
            {acquirers.map((acquirerInfo) => {
              const { acquirer, detail } = acquirerInfo;
              return (
                <>
                  <AcquirerHeader acquirer={acquirerInfo} key={acquirer} />
                  <Panel
                    key={detail.acquirer_id}
                    showArrow={false}
                    style={{
                      width: '100%',
                      background: colors.gray1,
                      borderRadius: '4px 4px 0 0 ',
                      marginBottom: 24,
                    }}
                    header={
                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          gap: 16,
                          padding: '8px 0',
                          backgroundColor: colors.gray2,
                        }}
                      >
                        {Number(currentActivePanel) ===
                        Number(detail.acquirer_id) ? (
                          <>
                            <span>Ocultar estabelecimentos</span>
                            <UpOutlined />
                          </>
                        ) : (
                          <>
                            <span>Mostrar estabelecimentos</span>
                            <DownOutlined />
                          </>
                        )}
                      </div>
                    }
                  >
                    <StTable
                      pagination={false}
                      size="small"
                      columns={columns(setClickedMerchant)}
                      dataSource={merchants || []}
                      scroll={
                        merchants.length >= 10
                          ? { x: true, y: 450 }
                          : { x: true }
                      }
                      loading={isLoadingMerchants}
                      onRow={(_, index) => {
                        return {
                          ref:
                            merchants.length === index + 1
                              ? lastMerchantElementRef
                              : null,
                        };
                      }}
                    />

                    <TableFooter />
                  </Panel>
                </>
              );
            })}
          </StCollapse>
          <BackTop />
        </>
      ) : acquirers?.length === 0 && !isAcquirerLoading ? (
        <StListSkeletonContainer style={{ padding: '32px 0' }}>
          <Empty
            title="Você não possui estabelecimentos cadastrados"
            reason="Seus estabelecimentos irão aparecer aqui"
          />
        </StListSkeletonContainer>
      ) : (
        <StSpin />
      )}
      <CnpjModal
        visibility={addModalVisibility}
        screen={3}
        closeModal={closeAddModal}
        isNextButtonLocked={isNextButtonLocked}
        setIsNextButtonLocked={setIsNextButtonLocked}
        acquirerSelected={acquirerSelected}
      />
    </>
  );
};

export default CnpjAcquirersList;
