import { privateRoutes } from './privateRoutes';

// Restrictions
const planFreeRestrictionRoutes = [
  '/bancos-intermediario',
  '/bancos-relatorios',
  '/gerenciar-usuarios',
  '/gerenciar-empresas',
  '/consultar-acessos',
  '/conciliacao-bancaria',
  '/analise-de-extrato-ofx-transacoes',
  '/importacao-arquivos',
  '/ajustes-intermediario',
  '/auditoria-pagamentos-intermediario',
  '/auditoria-pagamentos-relatorios',
  '/pagamentos-intermediario',
  '/antecipacao-intermediario',
  '/cancchbk-intermediario',
  '/pagamentos-relatorios',
  '/antecipacao-relatorios',
  '/ajustes-relatorios',
  '/cancchbk-relatorios',
  '/taxas-indicadores',
  '/conciliacao-vendas',
  '/conciliacao-vendas-relatorios',
  '/autorizar-parceiro',
  '/conciliacao-pagamentos',
  '/conciliacao-pagamentos-relatorios',
  '/conciliacao-bancaria-relatorios',
  '/taxas',
  '/cadastro-taxas',
  '/banking',
  '/conciliacao-retroativa',
  '/radar',
  '/regras-conciliacao',
  '/regras-ofx',
];

const planPagGratisRestrictionRoutes = [
  '/importacao-arquivos',
  '/conciliacao-vendas',
  '/conciliacao-bancaria',
  '/banking',
  '/conciliacao-retroativa',
  '/radar',
  '/regras-conciliacao',
  '/regras-ofx',
];

const planControleRestrictionRoutes = [
  '/importacao-arquivos',
  '/conciliacao-vendas',
  '/conciliacao-bancaria',
  '/banking',
  '/conciliacao-retroativa',
  '/radar',
  '/regras-conciliacao',
  '/regras-ofx',
];

const planGerencieRestrictionRoutes = [
  '/conciliacao-vendas',
  '/banking',
  '/conciliacao-retroativa',
  '/radar',
  '/regras-conciliacao',
  '/regras-ofx',
];

const planAdministrePlusRestrictionRoutes = [
  '/banking',
  '/conciliacao-retroativa',
  '/radar',
  '/regras-conciliacao',
  '/regras-ofx',
];

// Routes
const planControleRoutes = [...privateRoutes].filter(
  (item) => !planControleRestrictionRoutes.includes(item)
);

const planGerencieRoutes = [...privateRoutes].filter(
  (item) => !planGerencieRestrictionRoutes.includes(item)
);

const planAdministrePlusRoutes = [...privateRoutes].filter(
  (item) => !planAdministrePlusRestrictionRoutes.includes(item)
);

const planPagGratisRoutes = [...privateRoutes].filter(
  (item) => !planPagGratisRestrictionRoutes.includes(item)
);

const planFreeRoutes = [...privateRoutes].filter(
  (item) => !planFreeRestrictionRoutes.includes(item)
);

export const contextRoutes = {
  // CONTROLE
  1: {
    defaultRoute: '/dashboard',
    accessRoutes: [...planControleRoutes],
  },
  // GERENCIE
  2: {
    defaultRoute: '/dashboard',
    accessRoutes: [...planGerencieRoutes],
  },
  // ADMINISTRE
  3: {
    defaultRoute: '/dashboard',
    accessRoutes: [...planAdministrePlusRoutes],
  },
  // ADMINISTRE_PLUS
  4: {
    defaultRoute: '/dashboard',
    accessRoutes: [...planAdministrePlusRoutes],
  },
  // PAG_GRATIS
  6: {
    defaultRoute: '/dashboard',
    accessRoutes: [...planPagGratisRoutes],
  },
  // FREE
  7: {
    defaultRoute: '/dashboard',
    accessRoutes: [...planFreeRoutes],
  },
};
