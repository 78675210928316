import React, { useState } from 'react';
import { colors } from 'styles/colors';
import { MiniLogo } from 'commons/MiniLogo';
import {
  FeatureDescription,
  FeatureIcon,
  Features,
  Line,
  PlanDescription,
  PlanName,
  PlanNameContainer,
  Price,
  PriceCardContainer,
  PriceContainer,
  PriceTag,
  StButton,
} from './styled';
import { SpecialistContactModal } from './components/SpecialistContactModal';
import { postSpecialistContact } from 'services/specialistContact';
import { GiftTwoTone } from '@ant-design/icons';

export const PriceCard = ({
  plan = {},
  currentPlan = false,
  isMostChosenTagVisible = false,
  ...others
}) => {
  const {
    id = '',
    name = '',
    description = '',
    color = colors.gray7,
    price = '',
    hasPriceTag = false,
    hasIcon = false,
    mostChosen = false,
    features = [],
  } = plan;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [modalInfo, setModalInfo] = useState({
    title: '',
    description: '',
    modalType: '',
  });

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleSpecialistContact = async () => {
    if (!id || id === '') {
      return;
    }

    setIsLoading(true);
    setIsModalOpen(true);
    try {
      const response = await postSpecialistContact({ planIdRequested: id });
      const { code } = response;

      if (code === 201) {
        setModalInfo({
          modalType: 'success',
          title: 'Solicitação de contato enviada com sucesso',
          description:
            'Em breve um de nossos especialistas irá entrar em contato com você pelo seu e-mail/telefone para entender sua necessidade e o plano que mais se encaixa no seu negócio.',
        });
      }

      if (code === 208) {
        setModalInfo({
          modalType: 'warning',
          title: 'Sua solicitação está em andamento',
          description:
            'Por favor, aguarde o contato de um de nossos especialistas.',
        });
      }
      setIsLoading(false);
    } catch (error) {
      setModalInfo({
        modalType: 'error',
        title: 'Não foi possível enviar a solicitação',
        description:
          'Ocorreu um erro ao enviar a solicitação de contato com especialista. Tente novamente ou volte mais tarde.',
      });
      setIsLoading(false);
    }
  };

  return (
    <>
      <PriceCardContainer
        color={color}
        currentPlan={currentPlan}
        mostChosen={isMostChosenTagVisible && mostChosen}
        {...others}
      >
        <PlanNameContainer>
          <MiniLogo />
          <PlanName color={color}>{name}</PlanName>
        </PlanNameContainer>
        <PlanDescription>{description}</PlanDescription>

        <PriceContainer>
          <PriceTag>
            {hasIcon && (
              <GiftTwoTone
                twoToneColor={colors.primary6}
                style={{ fontSize: '24px' }}
              />
            )}
            {hasPriceTag && 'A partir de'}
          </PriceTag>
          <Price>{price}</Price>
        </PriceContainer>

        {currentPlan ? (
          <StButton type="default" disabled>
            Este é o seu plano atual
          </StButton>
        ) : (
          <StButton type="primary" onClick={handleSpecialistContact}>
            Solicitar contato com especialista
          </StButton>
        )}
        <Line />
        {features.map(({ icon, description: featureDescription, bold }) => (
          <Features key={featureDescription}>
            <FeatureIcon>{icon}</FeatureIcon>
            <FeatureDescription bold={bold}>
              {featureDescription}
            </FeatureDescription>
          </Features>
        ))}
      </PriceCardContainer>

      <SpecialistContactModal
        isModalOpen={isModalOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
        modalInfo={modalInfo}
        isLoading={isLoading}
        handleSpecialistContact={handleSpecialistContact}
      />
    </>
  );
};
