import React from 'react';
import { CheckOutlined } from '@ant-design/icons';
import { colors } from 'styles/colors';

export const plansDetails = [
  {
    id: 7,
    name: 'Free',
    description:
      'Tenha um resumo visual do desempenho da sua empresa. Acompanhe cada valor a receber e planeje o seu fluxo de caixa.',
    color: colors.primary4,
    price: 'Grátis',
    hasPriceTag: false,
    hasIcon: true,
    mostChosen: false,
    features: [
      {
        description: 'Detalhe de vendas',
        icon: <CheckOutlined />,
        bold: false,
      },
      {
        description: 'Detalhe de valores a receber',
        icon: <CheckOutlined />,
        bold: false,
      },
    ],
  },
  {
    id: 6,
    name: 'Pag Grátis',
    description:
      'Plano especial para clientes Pagbank, gratuito por 3 meses. Visão exclusiva dos dados da adquirente PagBank.',
    color: colors.lime5,
    price: 'Grátis por 3 meses',
    hasPriceTag: false,
    hasIcon: true,
    mostChosen: false,
    features: [
      {
        description: 'Todos os benefícios do plano Controle por tempo limitado',
        icon: <CheckOutlined />,
        bold: true,
      },
    ],
  },
  {
    id: 1,
    name: 'Controle',
    description:
      'Saiba sua projeção de caixa, audite todas as taxas, descontos e entenda se recebeu tudo aquilo que foi vendido.',
    color: colors.green14,
    price: 'R$ 150/mês',
    hasPriceTag: true,
    hasIcon: false,
    mostChosen: false,
    features: [
      {
        description:
          'Visão unificada das vendas de adquirentes, valores a receber e recebimentos bancários',
        icon: <CheckOutlined />,
        bold: false,
      },
      {
        description: 'Conciliação de pagamentos e taxas',
        icon: <CheckOutlined />,
        bold: false,
      },
    ],
  },
  {
    id: 2,
    name: 'Gerencie',
    description:
      'Faça a conciliação bancária de forma automatizada com o extrato OFX e veja se todos os pagamentos foram creditados corretamente.',
    color: colors.cyan11,
    price: 'R$ 200/mês',
    hasPriceTag: true,
    hasIcon: false,
    mostChosen: true,
    features: [
      {
        description: 'Todos os benefícios do plano Controle',
        icon: <CheckOutlined />,
        bold: true,
      },
      {
        description: 'Gestão do PIX',
        icon: <CheckOutlined />,
        bold: false,
      },
      {
        description: 'Conciliação bancária',
        icon: <CheckOutlined />,
        bold: false,
      },
    ],
  },
  {
    id: 4,
    name: 'Plus',
    description:
      'Integre as vendas registradas no seu ERP através de API e faça baixa automatizada dos títulos.',
    color: colors.gold11,
    price: 'Sob consulta',
    hasPriceTag: false,
    hasIcon: false,
    mostChosen: false,
    features: [
      {
        description: 'Todos os benefícios do plano Gerencie',
        icon: <CheckOutlined />,
        bold: true,
      },
      {
        description: 'Conciliação de vendas',
        icon: <CheckOutlined />,
        bold: false,
      },
      {
        description: 'Integração API',
        icon: <CheckOutlined />,
        bold: false,
      },
      {
        description: 'Acompanhamento de um gerente de sucesso',
        icon: <CheckOutlined />,
        bold: false,
      },
      {
        description: 'Treinamento personalizado',
        icon: <CheckOutlined />,
        bold: false,
      },
    ],
  },
];
