export const privateRoutes = [
  '/dashboard',
  '/vendas-intermediaria',
  '/vendas-relatorios',
  '/bancos-intermediario',
  '/bancos-relatorios',
  '/gerenciar-usuarios',
  '/gerenciar-empresas',
  '/consultar-acessos',
  '/valores-a-receber',
  '/valores-a-receber-relatorios',
  '/conciliacao-bancaria',
  '/analise-de-extrato-ofx-transacoes',
  '/importacao-arquivos',
  '/registro-de-implantacao',
  '/ajustes-intermediario',
  '/auditoria-pagamentos-intermediario',
  '/auditoria-pagamentos-relatorios',
  '/pagamentos-intermediario',
  '/antecipacao-intermediario',
  '/cancchbk-intermediario',
  '/pagamentos-relatorios',
  '/antecipacao-relatorios',
  '/ajustes-relatorios',
  '/cancchbk-relatorios',
  '/manutencao-cnpj',
  '/regras-ofx',
  '/taxas-indicadores',
  '/radar',
  '/conciliacao-vendas',
  '/conciliacao-vendas-relatorios',
  '/notificacoes',
  '/banking',
  '/autorizar-parceiro',
  '/conciliacao-pagamentos',
  '/conciliacao-pagamentos-relatorios',
  '/conciliacao-bancaria-relatorios',
  '/regras-conciliacao',
  '/conciliacao-retroativa',
  '/taxas',
  '/cadastro-taxas',
  '/planos',
  '/gerenciar-implantacao',
];
