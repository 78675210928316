import CryptoJS from 'crypto-js';

export const getHash = (content) => {
  return CryptoJS.MD5(content.toString()).toString();
};

export const encrypt = (content, key) => {
  CryptoJS.AES.encrypt(content, key);
};

export const decrypt = (encryptedContent, key) => {
  const ciphertext = CryptoJS.enc.Base64.parse(encryptedContent);

  // decryption taken from a stack overflow post,
  // in which the encryption is made on Python:
  // https://stackoverflow.com/questions/30990129/encrypt-in-python-decrypt-in-javascript

  // split iv and ciphertext

  const iv = ciphertext.clone();
  iv.sigBytes = 16;
  iv.clamp();
  ciphertext.words.splice(0, 4); // delete 4 words = 16 bytes
  ciphertext.sigBytes -= 16;

  const parsedKey = CryptoJS.enc.Utf8.parse(key);

  // decryption
  const decrypted = CryptoJS.AES.decrypt(
    { ciphertext: ciphertext },
    parsedKey,
    {
      iv: iv,
      mode: CryptoJS.mode.CBC,
    }
  );

  const encodedDecryption = CryptoJS.enc.Utf8.stringify(decrypted);
  const JSONParsedDecryption = JSON.parse(encodedDecryption);

  return JSONParsedDecryption;
};
