import { api } from './api';

const transformer = (response) => {
  return {
    acquirers: response.data.acquirers,
  };
};

export const getAcquirerList = async ({ all, clientId, isPixRequired }) => {
  const config = {
    params: {
      all,
      client_id: clientId || undefined,
      is_pix_required: isPixRequired || false,
    },
  };
  const response = await api().get('/acquirers', config);
  return transformer(response.data);
};

export const getAllAcquirerList = async () => {
  const response = await api().get('/acquirers', {});
  return transformer(response.data);
};

export const getAcquirerHasPointOfSale = async () => {
  const response = await api().get('/acquirers/pointofsale', {});
  return response.data.data;
};

export const getAcquirerVoucher = async () => {
  const response = await api().get('/acquirers/voucher', {});
  return response.data.data;
};

export const getAcquirerMerchants = async () => {
  const response = await api().get('/acquirer/merchants');
  return response.data.data;
};
